import React from "react";
import {useState} from "react";
import { NavLink } from "react-router-dom";
import logo from "../../images/LOGO.svg";
import "./SiteHeader.sass";
import { readItems } from "@directus/sdk/rest";
import directus from "../../hooks/directus";


// IMPORT HAMBURGER
import { Squeeze as Hamburger } from 'hamburger-react'


// GET NUMBER OF JOBS 
const jobPositions = await directus.request(readItems("jobs"));
const jobCount = await jobPositions.length

export default function SiteHeader() {

  const [isOpen, setOpen] = useState(false)

  const closeMenu = () => {
    setTimeout(() => {
      setOpen(false)}, 700);
   
  
  }


  return (<div className="siteheaderwrapper">      
    <Hamburger duration={0.8} label="Show menu" toggled={isOpen} toggle={setOpen} />


    <div className={`siteheader ${isOpen ? "active" : ""}`}>

      <img data-cursor-size="1000px" className="logo" src={logo} alt="JAKOBY.io logo" />
      <div className="menu">
        <NavLink data-cursor-size="100px" onClick={closeMenu} className="menulink basicLink projectsLink" to={"/"}>
          Projects
        </NavLink>

        <NavLink data-cursor-size="100px" onClick={closeMenu} className="menulink basicLink aboutLink" to={"/studio"}>
          Studio
        </NavLink>
        <NavLink data-cursor-size="100px" onClick={closeMenu} className="menulink basicLink contactLink" to={"/contact"}>
          Contact
        </NavLink>        
       {!!jobCount && <NavLink data-cursor-size="100px" onClick={closeMenu} className="menulink basicLink jobsLink" to={"/jobs"}>
          Jobs
          <div className="jobNumber">{jobPositions.length}</div>
        </NavLink>}
      </div>




{/* 
      <div className="language">
        <NavLink className="languageselector" to={"#"}>
          CZ
        </NavLink>
        <p className="languageselector">/</p>
        <NavLink className="languageselector" to={"#"}>
          EN
        </NavLink>
      </div> */}


      <div className="foot">
      with love, team JAKOBY.io
      
      </div>





    </div>
    </div>
  );
}
