import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import {useState} from 'react'


// //Page&Layouts imports
import ProjectList from "./components/projectlist/ProjectList";
import Projectpage from "./components/projectpage/ProjectPage";
import SiteHeader from "./components/siteheader/SiteHeader";
import StudioPage from "./components/studiopage/StudioPage";
import LoadingScreen from "./components/loadingScreen/LoadingScreen";
import Contact from "./components/contact/Contact";
import Jobs from "./components/jobs/Jobs";


// CURSOR EXTRA STUFF        // https://reactjsexample.com/a-creative-and-customizable-react-cursor-component/
import { Cursor } from 'react-creative-cursor';
import 'react-creative-cursor/dist/styles.css';


// IMPORT CSS/SASS FILES
import "./components/styles/fonts.css";
import "./components/styles/body.sass";
// import BackgroundAnimation from "./components/backgroundAnimation/BackgroundAnimation";
import { HelmetProvider } from 'react-helmet-async';


function App() {

  // UNLOAD THE LOADING SCREEN OVERLAY AFTER 4 SECONDS
  const [visibleLoadingScreen, setvisibleLoadingScreen] = useState(true)
  setTimeout(() => {setvisibleLoadingScreen(false)}, 3000)

  return (
    
    <>  
    <HelmetProvider>

        <Cursor 
        isGelly={false} 
        animationDuration={0.6}
        gellyAnimationAmount={100}
        cursorSize={50}
        />



      <Router>
        {/* <BackgroundAnimation/>  This is an animation of the background lines */}
       {visibleLoadingScreen && <LoadingScreen/>}
        <div data-cursor-exclusion className="body-wrapper">
          <SiteHeader/>
                <Routes>
                    <Route index exact path='/' element={< ProjectList />}/>
                    <Route path='/project/:id' element={< Projectpage />}/>
                    <Route path='/studio' element={< StudioPage />}/>
                    <Route path='/contact' element={< Contact />}/>
                    <Route path='/jobs' element={< Jobs />}/>
                </Routes>
        </div>
      </Router>
</HelmetProvider>
    </>
  );
}

export default App;

