import React from "react";
import logo from "../../images/LOGO.svg";

// IMPORTING ANIMATION
import { motion as m } from "framer-motion";

// IMPORT CSS AND SASS
import "./LoadingScreen.sass";

const svgtoInsert = () => {
  const index = Math.ceil(Math.random() * 5);
  const indexOfColor = Math.ceil(Math.random() * 8);
  // console.log(index);
  // console.log(indexOfColor);
  var colorOfLines = "GREY";
  var typeOfLine = "";

  if (indexOfColor === 1) {
    colorOfLines = "MINT";
  } else if (indexOfColor === 2) {
    colorOfLines = "GREEN";
  } else if (indexOfColor === 3) {
    colorOfLines = "YELLOW";
  } else if (indexOfColor === 4) {
    colorOfLines = "ORANGE";
  } else if (indexOfColor === 5) {
    colorOfLines = "RED";
  } else if (indexOfColor === 6) {
    colorOfLines = "PINK";
  } else if (indexOfColor === 7) {
    colorOfLines = "PURPLE";
  } else if (indexOfColor === 8) {
    colorOfLines = "BLUE";
  }

  if (index === 1) {
    typeOfLine = " one ";
  } else if (index === 2) {
    typeOfLine = " two ";
  } else if (index === 3) {
    typeOfLine = " three ";
  } else if (index === 4) {
    typeOfLine = " four ";
  } else if (index === 5) {
    typeOfLine = " five ";
  } else if (index === 6) {
    typeOfLine = " six ";
  } else if (index === 7) {
    typeOfLine = " seven ";
  } else if (index === 8) {
    typeOfLine = " eight ";
  }

  colorOfLines = colorOfLines + " stroke " + typeOfLine;

  // console.log(colorOfLines);

  if (index === 1) {
    return (
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="1240.3px"
        height="537.92px"
        viewBox="0 0 1240.3 537.92"
        style={{ overflow: "visible", enableBackground: "new 0 0 1240.3 537.92" }}>
        <line className={colorOfLines} x1="14.28" y1="500.56" x2="1226.01" y2="37.36" />
      </svg>
    );
  } else if (index === 2) {
    return (
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="1220.62px"
        height="390.16px"
        viewBox="0 0 1220.62 390.16"
        style={{ overflow: "visible", enableBackground: "new 0 0 1220.62 390.16" }}>
        <path className={colorOfLines} d="M33.79,368.76C70.93,310.12,149.1,84.26,426.63,46.7s385.57,91.86,487.9,161.03s178.55,121.94,299.73,141.48" />
      </svg>
    );
  } else if (index === 3) {
    return (
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="1175.59px"
        height="467.88px"
        viewBox="0 0 1175.59 467.88"
        style={{ overflow: "visible", enableBackground: "new 0 0 1175.59 467.88" }}>
        <path className={colorOfLines} d="M40,467.88c0-142.67,9.77-375.25,185.67-371.34s274.15,310.75,567.04,322.48S1135.86,4.69,1135.86,4.69" />
      </svg>
    );
  } else if (index === 4) {
    return (
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="1246.3px"
        height="642.08px"
        viewBox="0 0 1246.3 642.08"
        style={{ overflow: "visible", enableBackground: "new 0 0 1246.3 642.08" }}>
        <polyline className={colorOfLines} points="35.08,571.1 269.61,143.08 533.45,557.42 700.03,178.26 906.74,549.6 1211.63,19.96 " />
      </svg>
    );
  } else if (index === 5) {
    return (
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="646px"
        height="590.47px"
        viewBox="0 0 646 590.47"
        style={{ overflow: "visible", enableBackground: "new 0 0 646 590.47" }}>
        <path className={colorOfLines} d="M112.57,34.51C53.15,104.87-85.94,550.47,311.95,550.47S618.6,181.09,526.43,19.85" />
      </svg>
    );
  } else if (index === 6) {
    return (
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="1322.18px"
        height="526.71px"
        viewBox="0 0 1322.18 526.71"
        style={{ overflow: "visible", enableBackground: "new 0 0 1322.18 526.71" }}>
        <polyline className={"stroke six" + colorOfLines} points="10.49,488.11 1127.05,184.62 145.93,39.57 " />
      </svg>
    );
  } else if (index === 7) {
    return (
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="1030.88px"
        height="462.15px"
        viewBox="0 0 1030.88 462.15"
        style={{ overflow: "visible", enableBackground: "new 0 0 1030.88 462.15" }}>
        <polyline className={"stroke seven" + colorOfLines} points="0,40 990.88,40 990.88,462.15 " />
      </svg>
    );
  } else if (index === 8) {
    return (
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="1099.64px"
        height="616.58px"
        viewBox="0 0 1099.64 616.58"
        style={{ overflow: "visible", enableBackground: "new 0 0 1099.64 616.58" }}>
        <path className={"stroke eight" + colorOfLines} d="M1022.14,584.18C1009.99,575.38,40,118.05,40,118.05v425.08L1082.14,35.97" />
      </svg>
    );
  }
};

export default function LoadingScreen() {
  console.log("loading...");
  return (  

    <m.div className="whiteBG"
    initial={{ opacity: 1 }} animate={{ opacity: 0 }} transition={{delay:2, duration: 1, ease: "easeIn"}}>
      <m.div className="pagewrapper"
      
      initial={{ opacity: 0.01 }} animate={{ opacity: 1 }} exit={{opacity: 0}} transition={{ duration: 1, ease: "easeOut"}}>
        <div className="logowrapper">
          <img data-cursor-size="160px" className="logo" src={logo} alt="JAKOBY.io logo" />
        </div>

        <div className="infowrapper">
          <div className="column">We design to better the world!</div>
        </div>

        {/* <div className="backgrounds">
          <div className="strokewrapper">
            <div className="strokewrapper">{svgtoInsert()}</div>
          </div>
        </div> */}
      </m.div>
    </m.div>
  );
}
