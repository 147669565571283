import React from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import directus from "../../hooks/directus";
import { readItems } from "@directus/sdk/rest";
import parse from "html-react-parser";
import { v4 } from "uuid";


// IMPORT CSS AND SASS
import "./ProjectPage.sass";
import LargeImage from "../largeImage/LargeImage";

// Import HELMET - dynamic head 
import {Helmet } from "react-helmet-async";

// GET ALL PROJECTS TO DISPLAY
const data = await directus.request(readItems("projects"));
 

// SET QUALITY TOO IMAGES
const th_width = 600
const th_height = 600
const th_fit = "outside"
const th_quality = 60



export default function Projectpage() {
  // GET PROJECT TO DISPLAY
  const { id } = useParams();
  const projectData = data.filter((project) => project.id === id);
  const project = projectData[0];
  // console.log(project);




// LARGER IMAGE STATE TOGGLE
const [largeImageModal, setlargeImageModal] = useState(false)
const [largeImageID, setlargeImageID] = useState(null)

  const openModal = (event) => {
    setlargeImageID(event.target.getAttribute("data-imageid"))
    setlargeImageModal(true)
  }

  const closeModal = (event) => {
    setlargeImageModal(false)
    // console.log("clicked");
    setlargeImageID(null)

  }

  return (
    <div className="content-box">
        <Helmet>
      <title>JAKOBY.io - {project.title}</title>
      <meta name="description" content={project.subtitle}/>
    </Helmet>
              {largeImageModal && <LargeImage handleClick={closeModal}
 imageID={largeImageID}/>}
      <div className="pageContainer">

        <div className="imagePanel">

          <img onClick={openModal} alt={project.title + " project photo"} data-cursor-size="160px" data-imageid={project.cover_image} className="imageTop"  src={process.env.REACT_APP_DIRECTUS_URL + "assets/" + project.cover_image + "?fit="+ th_fit +"&width=" + th_width + "&height=" + th_height + "&quality="+ th_quality} />
          {project.image_1 && (
            <img onClick={openModal} alt={project.title + " project photo"} data-cursor-size="160px" data-imageid={project.image_1} className="imageTop" src={process.env.REACT_APP_DIRECTUS_URL + "assets/" + project.image_1 + "?fit="+ th_fit +"&width=" + th_width + "&height=" + th_height + "&quality="+ th_quality} />
          )}
          {project.image_2 && (
            <img onClick={openModal} alt={project.title + " project photo"} data-cursor-size="160px" data-imageid={project.image_2} className="imageTop" src={process.env.REACT_APP_DIRECTUS_URL + "assets/" + project.image_2 + "?fit="+ th_fit +"&width=" + th_width + "&height=" + th_height + "&quality="+ th_quality} />
          )}
          {project.image_3 && (
            <img onClick={openModal} alt={project.title + " project photo"} data-cursor-size="160px" data-imageid={project.image_3} className="imageTop" src={process.env.REACT_APP_DIRECTUS_URL + "assets/" + project.image_3 + "?fit="+ th_fit +"&width=" + th_width + "&height=" + th_height + "&quality="+ th_quality} />
          )}
          {project.image_4 && (
            <img onClick={openModal} alt={project.title + " project photo"} data-cursor-size="160px" data-imageid={project.image_4} className="imageTop" src={process.env.REACT_APP_DIRECTUS_URL + "assets/" + project.image_4 + "?fit="+ th_fit +"&width=" + th_width + "&height=" + th_height + "&quality="+ th_quality} />
          )}
          {project.image_5 && (
            <img onClick={openModal} alt={project.title + " project photo"} data-cursor-size="160px" data-imageid={project.image_5} className="imageTop" src={process.env.REACT_APP_DIRECTUS_URL + "assets/" + project.image_5 + "?fit="+ th_fit +"&width=" + th_width + "&height=" + th_height + "&quality="+ th_quality} />
          )}
          {project.image_6 && (
            <img onClick={openModal} alt={project.title + " project photo"} data-cursor-size="160px" data-imageid={project.image_6} className="imageTop" src={process.env.REACT_APP_DIRECTUS_URL + "assets/" + project.image_6 + "?fit="+ th_fit +"&width=" + th_width + "&height=" + th_height + "&quality="+ th_quality} />
          )}
          {project.image_7 && (
            <img onClick={openModal} alt={project.title + " project photo"} data-cursor-size="160px" data-imageid={project.image_7} className="imageTop" src={process.env.REACT_APP_DIRECTUS_URL + "assets/" + project.image_7 + "?fit="+ th_fit +"&width=" + th_width + "&height=" + th_height + "&quality="+ th_quality} />
          )}
          {project.image_8 && (
            <img onClick={openModal} alt={project.title + " project photo"} data-cursor-size="160px" data-imageid={project.image_8} className="imageTop" src={process.env.REACT_APP_DIRECTUS_URL + "assets/" + project.image_8 + "?fit="+ th_fit +"&width=" + th_width + "&height=" + th_height + "&quality="+ th_quality} />
          )}
          {project.image_9 && (
            <img onClick={openModal} alt={project.title + " project photo"} data-cursor-size="160px" data-imageid={project.image_9} className="imageTop" src={process.env.REACT_APP_DIRECTUS_URL + "assets/" + project.image_9 + "?fit="+ th_fit +"&width=" + th_width + "&height=" + th_height + "&quality="+ th_quality} />
          )}
          {project.image_10 && (
            <img onClick={openModal} alt={project.title + " project photo"} data-cursor-size="160px" data-imageid={project.image_10} className="imageTop" src={process.env.REACT_APP_DIRECTUS_URL + "assets/" + project.image_10 + "?fit="+ th_fit +"&width=" + th_width + "&height=" + th_height + "&quality="+ th_quality} />
          )}
          {project.image_11 && (
            <img onClick={openModal} alt={project.title + " project photo"} data-cursor-size="160px" data-imageid={project.image_11} className="imageTop" src={process.env.REACT_APP_DIRECTUS_URL + "assets/" + project.image_11 + "?fit="+ th_fit +"&width=" + th_width + "&height=" + th_height + "&quality="+ th_quality} />
          )}
          {project.image_12 && (
            <img onClick={openModal} alt={project.title + " project photo"} data-cursor-size="160px" data-imageid={project.image_12} className="imageTop" src={process.env.REACT_APP_DIRECTUS_URL + "assets/" + project.image_12 + "?fit="+ th_fit +"&width=" + th_width + "&height=" + th_height + "&quality="+ th_quality} />
          )}
        </div>
        <div className="projectpageInfo">
          <h1 className={`title projectLink ${project.subtitle}`}>{project.title}</h1>
          <div className="subtitle">{project.subtitle}</div>
          {project.description && <div className="description">{parse(project.description)}</div>}

          <div className="projectinfo">
          {project.cooperation && (
              <>
                <div className="key">Collaboration </div>
                <div className="value">{parse(project.cooperation)} </div>
              </>
            )}
            {(project.country && project.city) && (
              <>
                <div className="key">Location </div> <div className="value">{project.country}, {project.city}</div>
              </>
            )}
            {project.project_date && (
              <>
                <div className="key">Year </div>
                <div className="value">{project.project_date.substring(0, 4)} </div>
              </>
            )}
            {project.category && (
              <>
                <div className="key">Category </div>
                <div className="value">{project.category} </div>
              </>
            )}

            {project.phase_current && (
              <>
                <div className="key">Phase </div>
                <div className="value">{project.phase_current} </div>
              </>
            )}

            {project.tags && (
              <>
              <div className="key">Tags </div>

              {project.tags.map((tag) => (
                    <div className="tagBox" key={v4()}>
                      <div className="tag">{tag}</div>
                      {/* <div className="comma">, </div> */}
                    </div> ))}
                  
              </>)
            }




{/* 

            <div className="key">Tags </div>
            <div className="value">{project.tags} </div> <br />


            {project.tags &&
                  project.tags.map((tag) => (
                    <div className="tagBox" key={v4()}>
                      <div className="gap">|</div>
                      <div className="tag">{tag}</div>
                    </div>
                  ))} */}



          </div>
          <br />
        </div>
      </div>
    </div>
  );
}
