import React from "react";

// IMPORT CSS AND SASS
import "./Contact.sass";

// Import HELMET - dynamic head 
import {Helmet } from "react-helmet-async";



export default function StudioPage() {



  return (
    <div className="content-box">

<Helmet>
      <title>JAKOBY.io - contact us</title>
    </Helmet>
      <h1>Contact us</h1>

      <div className="contact-container">
        <div className="contact-box">
          <h3>JAKOBY.io s.r.o.</h3>
          <p>Vrchní 86/30</p>
          <p>Opava - Kateřinky</p>
          <p>ZIP 747 05</p>
          <p>Czechia</p>
          <br />
          <p>Business reg. no. 11671971</p>
          <p>VAT no. CZ11671971</p>
        </div>

        <div className="contact-box">
          <h3>Feel free to email us at:</h3>
          <p>info@jakoby.io</p>
</div>
          {/* <div className="contact-box">

          <h3>Take a look at recent updateas at</h3>
          <p>LinkedIN</p>
          <p>Instagram</p>
        </div> */}
        
      </div>
    </div>
  );
}
