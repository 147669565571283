import React from 'react'
import "./LargeImage.sass"



// SET QUALITY TOO IMAGES
const th_width = 1920
const th_height = 1080
const th_fit = "outside"
const th_quality = 80


function LargeImage({imageID, handleClick}) {
  
  return (
<div className='modalLargeImage' onClick={handleClick}>

<img 
className="largeImageModal"  
src={process.env.REACT_APP_DIRECTUS_URL + "assets/" + imageID + "?fit="+ th_fit +"&width=" + th_width + "&height=" + th_height + "&quality="+ th_quality}
alt="Enlarged modal"/>
</div>  )
}

export default LargeImage