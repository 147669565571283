import React from "react";
import { readItems } from "@directus/sdk/rest";
import directus from "../../hooks/directus";
import { v4 } from "uuid";
import parse from "html-react-parser";


// IMPORT CSS AND SASS
import "./StudioPage.sass";

// Import HELMET - dynamic head 
import {Helmet } from "react-helmet-async";

// GET ALL PEOPLE
const peoples = await directus.request(readItems("bio"));
const pastcolab = await directus.request(readItems("pastcolab"));
// console.log(pastcolab[0]);
const randomlysortedpeople = peoples.sort(() => Math.random() - 0.5);


export default function StudioPage() {

  return (


<div className="content-box">

<Helmet>
      <title>JAKOBY.io - about us</title>
    </Helmet>

      <h1>At JAKOBY.io</h1>
      <div className="contact-container">
        <div className="contact-box manifesto">
          <p>We believe in shaping the future through design and in creating flourishing environments that improve people’s well-being. </p>
          
          <p>Every project is a unique expression of the client’s ambitions coming to life, users’ demands reflected in the design and the local contexts shaping their form. </p>
          <p>We make future-oriented projects with integrated design, as an intersection between architecture, engineering, and technology. </p>
          <p>Our designs are the result of iterative processes with research-based knowledge. The concepts we create and their executions are rooted in purpose and functional reasonings that are complimented by their forms.</p>
          
        </div>


   
        <div className="contact-box">
        <h2>Founding partners</h2>
        <br/>
        {randomlysortedpeople.map((person) => (
          <div  key={v4()}>
            <strong>{person.name}</strong>
            <div>{parse(person.description)}</div>
          </div>
        ))}</div>






        <div className="contact-box">
        <h3>Past collaborators</h3>
          {parse(pastcolab[0].text)}
        </div>







{/* 

        <div className="contact-box">
        <h3>Achievements</h3>
        <p>2003 - this </p>
        <p>3000 - that </p>
        </div> */}


        </div>
    </div>
  );
}
 