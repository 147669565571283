import React from "react";
import { Link } from "react-router-dom";
import { readItems } from "@directus/sdk/rest";
import directus from "../../hooks/directus";
import { v4 } from "uuid";

//  IMPORT CSS
import "./projectlist.sass";

// Import HELMET - dynamic head 
import {Helmet } from "react-helmet-async";

// GET ALL PROJECTS TO DISPLAY
const data = await directus.request(readItems("projects"));

// TODO Error hangling for fetch error
const randomlysortedprojects = data.sort(() => Math.random() - 0.5);

// console.log(randomlysortedprojects);

export default function ProjectList() {



  return (
    <>
    <Helmet>
      <title>JAKOBY.io - architectural office</title>
    </Helmet>
      <div className="content-box">
        {randomlysortedprojects.map((project) => (
          <Link data-cursor-size="160px" key={project.id} to={`/project/${project.id}`} className="project-card">
            <>
              <div className="descriptionContainer">
                <div className="year">{project.project_date.substring(0, 4)}</div>
                <div className={`subtitle projectLink}`}>{project.subtitle}</div>
                <div className={`title projectLink ${project.category}`}>{project.title}</div>
                {project.tags &&
                  project.tags.map((tag) => (
                    <div className="tagBox" key={v4()}>
                      <div className="gap">|</div>
                      <div className="tag">{tag}</div>
                    </div>
                  ))}
                <div className="gap">|</div>
              </div>
              <div className="imageContainer">
                {project.cover_image && (
                  <img
                    className="projectImage"
                    src={
                      process.env.REACT_APP_DIRECTUS_URL + "assets/" + project.cover_image + "?download&quality=50" // TODO Set Quality
                    }
                    alt={
                      process.env.REACT_APP_DIRECTUS_URL + "assets/" + project.cover_image.title // TODO Fix ALT
                    }
                  />
                )}
              </div>
            </>
          </Link>
        ))}
      </div>
    </>
  );
}
