import React from "react";
import { readItems } from "@directus/sdk/rest";
import directus from "../../hooks/directus";
import { v4 } from "uuid";
import parse from "html-react-parser";

// IMPORT CSS AND SASS
import "./Jobs.sass";

// Import HELMET - dynamic head 
import {Helmet } from "react-helmet-async";

// GET ALL JOBS
const jobPositions = await directus.request(readItems("jobs"));
// console.log(jobPositions)

jobPositions.sort((a, b) => a.position - b.position)

// console.log(jobPositions)


export default function Jobs() {


  return (
    <div className="content-box">
        <Helmet>
      <title>JAKOBY.io - job offers</title>
    </Helmet>
    <h1>Current job openings</h1>
    <p>Send your portfolio and CV to <strong>info@jakoby.io</strong></p>

    <div className="jobs-container">
        {jobPositions.map((job) => (
          <div className="job-box" key={v4()}>
            <h3>{job.Title}</h3>
            <div>{parse(job.Description)}</div>
          </div>
        ))}
      </div>
    </div>
  );
}
